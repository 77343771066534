.home {
min-height: 100vh;
  .banner {
    background-image: url("../../Assets/images/bannerD.svg");
    background-position: center;
    background-size: cover;
    background-repeat: repeat-x ;
    height: 30vh;
    width: 100%;
  }
  .row {
    justify-content: center;
    margin: 0;
    min-height: 70vh;
    .grid {
        padding: 5rem 3.5rem;
      .slidContent {
        text-align: center;
      }
      .btnRow{
        margin-top: 6rem;
        justify-content: center;
      }
    }
  }
}
