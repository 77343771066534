.firstScreen {
    min-height: 100vh;
    .row {
        justify-content: center;
        margin: 0;
        min-height: calc(70vh - 54px);
        .grid {
            padding: 5rem 3.7rem;
        }
    }
    .btnRow {
        margin-top: 6rem;
        justify-content: center;
    }
}
