.steps{
    text-align: center;
    .lable{
        font-size: 16px;
        color: var(--color-light);
    }
    .title{
        font-size: 24px;
        color: var(--color-primary);
        margin: 2rem 0;
        font-family: ubuntu;
    }
    .desc{
        font-size: 18px;
        color: var(--color-primary);
        margin-bottom: 3.2rem;
    }
}