.buttonBox{
  width: min-content;
  display: contents;
}
.buttonHref{
  width: min-content;
  text-decoration: none;
  margin: auto;
  
}
.icon_bttn {
  margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:18px;
    padding: 7px 13px;
    box-shadow: 0px 0px 14px rgba(129, 144, 192, 0.37);
    height: 40px;
    width: max-content;
    background-color: var(--color-primary-dark);
    cursor: pointer;
    padding: 10px 15px;
    img{
      margin-left: 10px;
      width: 12px;
    }
    .leftIcon{
      margin-right: 12px;
      margin-left: 0       ;
    }
    .icon_bttn_p {
      color: var(--color-white);
      font-size: 16px;
      margin-top: 0px;
      // text-transform: capitalize;
      letter-spacing: 0.2px;
      
    }
  }
  .icon_bttn:hover {
    transform: translateY(-2px);
  }
  
  
  
  