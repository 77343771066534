.thankyou{
  min-height: 100vh;
    .banner {
        background-image: url("../../Assets/images/bannerD.svg");
        background-position: center;
        background-size: cover;
        background-repeat: repeat-x ;
        height: 30vh;
        width: 100%;
        @media screen and (max-width: 428px) {
          background-image: url('../../Assets/images/tmb.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom;
          margin-top: 10px
        }
      }
      .row {
        justify-content: center;
        margin: 0;
       min-height: calc(70vh - 54px);
        .grid {
            padding: 5rem 3.7rem;
         h3{
             font-size: 29px;
             font-family: ubuntu;
             text-align: center;
         }
         .desc{
             font-size: 18px;
             line-height: 26px;
             text-align: center;
             margin-top: 14px;
         }
          .tBtn{
            margin-top: 61px;
            min-width: 181px;
            height: 36px;
          }
        }
      }
}