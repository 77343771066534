.questionDots {
  display: flex;

  & > div {
    flex: 1;
    background-color: #cfd3e8;
    height: 6px;
    margin: 0 2px;
    border-radius: 45px;
    &.active {
      background-color: var(--color-success);
    }
  }
}

/*   Range Slider   */
.range {
  .lblSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    p {
      font-size: 14px;
      color: var(--color-primary);
      // text-transform: capitalize;
    }
  }
  .qs {
    font-size: 24px;
    font-family: ubuntu;
    text-align: center;
    color: var(--color-dark);
    margin-bottom: 5rem;
  }
}
/*   Range Slider   */
/*   Lable   */
.lableSec {
  background-color: var(--color-info-light);
  padding: 0 2.1rem;
  border-radius: 7.4rem;
  width: max-content;
  height: 33px;
  display: flex;
  align-items: center;
  p {
    font-size: 18px;
    color: var(--color-primary);
    font-family: ubuntu;
    // text-transform: capitalize;
    line-height: 23px;
  }
}
/*   Lable   */

.userCard {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #f1f4fb;
  .profSec {
    height: 52px;
    width: 52px;
    border-radius: 100%;
    background-color: var(--color-primary-light);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .title {
    font-size: 16px;
    line-height: 23px;
    font-family: heebo;
    color: var(--color-primary);
    margin-left: 21px;
  }
}

/*------------------------*/
/*       List Card        */
/*------------------------*/
.listCard {
  background-color: var(--color-white);
  border: 1px solid #e2e5ee;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  .header {
    display: flex;
    align-items: center;
    .nGrid {
      height: 34px;
      width: 34px;
      border-radius: 100%;
      background-color: var(--color-primary-light);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 20px;
        font-family: heebo700;
        color: var(--color-primary-dark);
        text-align: center;
      }
    }
    .title {
      font-size: 16px;
      font-family: heebo;
      color: var(--color-primary);
      margin-left: 8px;
      flex: 1;
    }
  }
  ul {
    li {
      font-size: 16px;
      font-family: heebo;
      color: var(--color-primary);
      margin-left: 8px;
      flex: 1;
      margin-top: 20px;
      margin-left: 25px;
      &::marker{
        color: var(--color-primary-dark);
        font-size: 24px;
        text-align: center;
      }
    }
  }
}