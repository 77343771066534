.stepCard {
  background-color: var(--color-white);
  box-shadow: -1px -5px 28px rgba(156, 166, 215, 0.308056);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 2rem 4rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  color: var(--color-primary);
  &:hover,
  &.active {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  p {
    font-size: 20px;
    font-weight: 500;
  }
  img {
    position: absolute;
    bottom: 25px;
    right: 31px;
    transform: scale(1.5);
    border-radius: 0px 0px 10px 0px;
  }
}

/*  Slider Card  */
.sliderCard {
  .imgSec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .title {
    font-size: 24px;
    color: var(--color-primary);
    font-family: ubuntu;
    text-align: center;
    line-height: 127%;
    margin-top: 24px;
  }
  .desc {
    font-size: 18px;
    color: var(--color-primary);
    line-height: 26px;
    text-align: center;
    margin-top: 2rem;
  }
  .rgSlider {
    margin-top: 5rem;
    @media screen and (max-width: 528px) {
      width: 80%;
      margin: 5rem auto;
    }
  }
}

/*------------------------*/
/*       Catg Card        */
/*------------------------*/

.catgCard {
  background: var(--color-white);
  border: 1px solid #f1f4fb;
  border-radius: 10px;
  padding: 16px 20px;
  margin-bottom: 16px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head {
      padding: 5px 10px;
      p {
        font-size: 14px;
        font-family: heebo500;
      }
    }
    .lbl {
      font-size: 36px;
      color: #4059ad;
      font-family: heebo700;
    }
  }
  .dtl {
    font-size: 16px;
    color: var(--color-primary);
    margin-top: 16px;
  }
  .chat {
    position: relative;
    padding: 16px 23px;
    border-radius: 16px;
    width: max-content;
    max-width: 100%;
    margin-top: 10px;
    @media screen and(min-width: 768px) {
      max-width: 80%;
    }
    .title {
      font-size: 16px;
      font-family: heebo700;
    }
    .msg {
      font-size: 16px;
      font-family: heebo;
      color: var(--color-primary);
      margin-top: 4px;
    }
  }
  .dTriangle {
    margin-right: auto;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 20px solid #f0f1fd;
      border-bottom: 15px solid transparent;
      position: absolute;
      z-index: 1;
      top: -6px;
      left: -8px;
      transform: rotate(27deg);
    }
  }
  .pTriangle {
    margin-left: auto;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 20px solid #e5edff;
      border-bottom: 15px solid transparent;
      position: absolute;
      z-index: 1;
      top: -3px;
      right: -6px;
      transform: rotate(85deg);
    }
  }
}

/*------------------------*/
/*       Catg Card        */
/*------------------------*/
/*------------------------*/
/*      Check Card        */
/*------------------------*/

.checkCard {
  background: var(--color-white);
  border: 1px solid #f1f4fb;
  border-radius: 16px;
  padding: 12px 25px;
  .title {
    font-size: 23px;
    color: var(--color-primary);
    font-family: ubuntu;
    text-align: center;
  }
  .descSec {
    padding: 14px 23px;
    background-color: #f0f1fd;
    border-radius: 15px;
    margin: 75px 0 18px;
    p {
      font-size: 16px;
      color: var(--color-primary);
      line-height: 23.5px;
      text-align: center;
    }
  }
}

/*------------------------*/
/*      Check Card        */
/*------------------------*/
/*------------------------*/
/*      Match Card        */
/*------------------------*/

.matchCard {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--color-white);
  border: 1px solid #f1f4fb;
  border-radius: 16px;
  margin-top: 8px;
  .imgSec {
    height: 52px;
    width: 52px;
    border-radius: 100%;
    background-color: #4059ad;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .dtl {
    font-size: 16px;
    color: var(--color-primary);
    span {
      font-family: heebo700;
    }
  }
}

/*------------------------*/
/*      Match Card        */
/*------------------------*/
/*--------------------------*/
/*      Journey Card        */
/*--------------------------*/

.jcrd {
  height: 100px;
  font-size: 18px;
  background-color: var(--color-white);
  border-radius: 12px;
  box-shadow: -1px -5px 28px rgba(156, 166, 215, 0.308056);
  padding: 24px 27px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 24px;
  color: var(--color-primary);
  cursor: pointer;
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  .title {
    font-size: 18px;
    width: 60%;
  }
  img {
    position: absolute;
    right: 0;
    width: 135px;
  }
}

/*--------------------------*/
/*      Journey Card        */
/*--------------------------*/

.parent_check {
  margin: 20px 0 0;
  color: var(--color-primary);
  cursor: pointer;
  .form_check_new {
    margin: 0;
  }
  &:hover,
  &.active {
    .form_check_new {
      background-color: var(--color-primary-dark);
      color: var(--color-white);
    }
  }
}

/*--------------------------*/
/*      Text Section        */
/*--------------------------*/
.textSec {
  padding: 16px 20px;
  border-radius: 15px;
  background-color: #f0f1fd;
  p {
    font-size: 16px;
    font-family: heebo;
    color: var(--color-primary);
  }
  .checkLabel {
    height: 18px;
    width: 18px;
  }
}
/*--------------------------*/
/*      Review Card         */
/*--------------------------*/

.reviewCard {
  background-color: var(--color-white);
  border-radius: 16px;
  border: 1px solid #f1f4fb;
  padding: 16px;
}
