.stepEnd {
  min-height: 100vh;
  .row {
    justify-content: center;
    margin: 0;
    min-height: calc(100vh - 54px);
    .grid {
      padding: 3.6rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .content {
        margin-bottom: 50px;
        // width: 70%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        .head {
          text-align: center;
          font-size: 24px;
          color: var(--color-primary);
          font-family: ubuntu;
          line-height: 127.9%;
          margin: 16px 0 10px;
        }
        .desc{
          text-align: center;
            font-size: 18px;
            color: var(--color-primary);
            line-height: 26px;
        }
      }
      .btnRow {
        margin-top: 6rem;
        justify-content: center;
      }
    }
  }
}
