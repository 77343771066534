body {
  margin: 0;
  background: linear-gradient(0.76deg, #fcfcfe -4.74%, #e9ebfc 300.53%);
  min-height: 100vh;
  width: 100%;
  color: var(--color-primary);
  overflow-x: hidden;
  font-family: heebo;
  scroll-behavior: smooth;
}

p {
  margin-bottom: 0px;
}

html {
  font-size: 62.5%;
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 51%;
  }
}

@media screen and (max-width: 471px) {
  html {
    font-size: 40%;
  }
}

@font-face {
  font-family: ubuntu400;
  src: url("./Assets/fonts/Heebo-Regular.ttf");
}
@font-face {
  font-family: ubuntu;
  src: url("./Assets/fonts/Ubuntu-Medium.ttf");
}
@font-face {
  font-family: ubuntu700;
  src: url("./Assets/fonts/Ubuntu-Bold.ttf");
}

@font-face {
  font-family: heebo;
  src: url("./Assets/fonts/Heebo-Regular.ttf");
}
@font-face {
  font-family: heebo500;
  src: url("./Assets/fonts/Heebo-Medium.ttf");
}
@font-face {
  font-family: heebo700;
  src: url("./Assets/fonts/Heebo-Bold.ttf");
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-info);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background-color: var(--color-primary-dark);
}

:root {
  --color-primary: #112548;
  --color-primary-dark: #4059ad;
  --color-primary-light: #ccdbff;
  --color-info: #bdd2f9;
  --color-info-light: #ccdbff;
  --color-success: #68dab7;
  --color-dark: #000000;
  --color-white: #fff;
  --color-light: #76787c;
  --color-text: #1125486b;
}
.heebo500 {
  font-family: heebo500;
}
.ubuntu700 {
  font-family: ubuntu700;
}
.ubuntu500 {
  font-family: ubuntu;
}
.row {
  @media screen and (max-width: 528px) {
    margin: 0px;
  }
}
/*------------------------*/
/*         Colors         */
/*------------------------*/

// Background colors
.bgPrimary {
  background-color: #e5edff;
}
.bgLight {
  background-color: #f0f1fd;
}

// Colors
.primaryDark {
  color: var(--color-primary);
}
.primaryLight {
  color: var(--color-primary-dark);
}

.title {
  font-size: 29px;
  line-height: 127%;
  font-family: ubuntu;
  color: var(--color-primary);
  @media screen and (max-width: 428px) {
    text-align: center;
  }
}
.title24 {
  font-size: 24px;
  line-height: 127%;
  font-family: ubuntu;
  color: var(--color-primary);
  @media screen and (max-width: 428px) {
    text-align: center;
  }
}
.title22 {
  font-size: 22px;
  line-height: 127%;
  font-family: ubuntu;
  color: var(--color-primary);
  text-align: left;
  margin-bottom: 10px;
  @media screen and (max-width: 428px) {
    text-align: center;
    font-size: 18px;
  }
}
.title22r {
  @extend .title22;
  font-family: ubuntu400;
}

.desc {
  font-size: 18px;
  line-height: 26px;
  margin: 3.2rem 0;
  font-family: heebo;
  @media screen and (max-width: 428px) {
    text-align: center;
  }
}
.desc18 {
  font-size: 18px;
  line-height: 26px;
  margin: 36px 0 19px;
  @media screen and (max-width: 428px) {
    text-align: center;
  }
}
.desc18b {
  font-size: 18px;
  line-height: 26px;
  margin: 2rem 0;
  @extend .heebo500;
  @media screen and (max-width: 428px) {
    text-align: center;
  }
}
.desc16 {
  font-size: 16px;
  line-height: 23px;
  margin: 20px 0;
}

/*------------------------*/
/*       Text Align       */
/*------------------------*/

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.underline {
  text-decoration: underline;
}
/*------------------------*/
/*        Spacing         */
/*------------------------*/
.mt-0 {
  margin-top: 0;
}
.mt-15 {
  margin-top: 15px;
}
.mt-30 {
  margin-top: 30px;
}
.my-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-15 {
  margin-bottom: 15px;
}
.ml-15 {
  margin-left: 15px;
  @media screen and (max-width: 428px) {
    margin-left: 0;
  }
}
.ml-20 {
  margin-left: 20px;
  @media screen and (max-width: 428px) {
    margin-left: 0;
  }
}

/*------------------------*/
/*       App Header       */
/*------------------------*/

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 18px;
  background-color: var(--color-primary);
  .logo {
    width: 80px;
    margin: 0 auto;
  }
  .back {
    width: 11px;
    cursor: pointer;
  }
}

/*------------------------*/
/*       App Header       */
/*------------------------*/
/*------------------------*/
/*       App NavBar       */
/*------------------------*/

.app-navbar {
  .navbar {
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    // min-height: 10.8rem;
    padding: 4.4rem 0;

    .back-link {
      font-size: 1.4rem;
      color: var(--color-secondry);
      opacity: 0.7;
    }

    .mbBtn {
      height: 29px;
      width: 96px;
      // visibility: hidden;
      display: none;
      margin: 0 1rem 0 auto;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    .navbar-brand {
      padding: 0;
      margin: 0;
      // flex: 1;

      @media screen and (max-width: 768px) {
        flex: unset;
      }

      img {
        width: 20rem;
      }
    }

    .navbar-collapse {
      // flex-grow: unset;
      // flex: 1.5;
      justify-content: flex-end;

      @media screen and (max-width: 768px) {
        flex: unset;
        flex-basis: 100%;
        align-items: center;
      }

      .navbar-nav {
        flex: 1;
        justify-content: space-evenly;
        .nav-link {
          color: var(--color-dark);
          font-size: 1.6rem;
          font-family: Montserrat600;
          // margin: 0 2.2rem;
          &:hover {
            color: var(--color-success-dark);
            font-family: Montserrat600;
          }
        }

        @media screen and (max-width: 768px) {
          align-items: center;
        }
      }

      .navbar-btn {
        height: 4.7rem;
        width: 17.2rem;
        border-radius: 5px;
        border: 3px solid var(--color-primary);
        background-color: transparent;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

/*------------------------*/
/*       App NavBar       */
/*------------------------*/
/*------------------------*/
/*       Check Box        */
/*------------------------*/

.chkbx {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .qs {
    font-size: 24px;
    color: var(--color-primary);
    text-align: center;
  }
  .form-check {
    height: 50px;
    align-items: center;
    display: flex;
    padding: 0 20px;
    background-color: var(--color-white);
    box-shadow: 0px 2px 7px rgba(183, 192, 235, 0.410564);
    border-radius: 12px;
    margin: 20px 0 0;
    color: var(--color-primary);
    &:hover {
      background-color: var(--color-primary-dark);
      color: var(--color-white);
    }
    .form-check-input {
      width: 18px;
      height: 18px;
      margin: 0 15px 0 0;
      background-color: transparent;
      border: 1.3px solid #b8bdda;
      border-radius: 5px;
      cursor: pointer;
    }
    .form-check-label {
      font-size: 16px;
      font-family: heebo500;
      // color: var(--color-primary);
    }
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: url("./Assets/images/check.svg");
  }
  .form-check-input:focus {
    outline: 0;
    box-shadow: none;
  }
}

/*------------------------*/
/*       Check Box        */
/*------------------------*/
/*------------------------*/
/*        Select DD       */
/*------------------------*/

.app-select {
  margin: 1.5rem auto;
  width: 60%;
  @media only screen and (max-width: 428px) {
    width: 100%;
  }
  .form-select {
    width: 100%;
    padding: 11px 16px;
    font-size: 16px;
    color: var(--color-primary-dark);
    background-color: var(--color-white);
    border: none;
    border-radius: 12px;
    box-shadow: 1px 5px 28px rgba(156, 166, 215, 0.308056);
  }
}

/*------------------------*/
/*        Select DD       */
/*------------------------*/
/*------------------------*/
/*       App CheckBox     */
/*------------------------*/

.check-container {
  display: flex;
  align-items: center;

  .app-checkbox {
    height: 2rem;
    width: 2rem;
    border: 1px solid #c8d0da;
    border-radius: 4px;
    margin-right: 6px;

    &:checked {
      background-color: #1d4655;
    }
  }

  .checklable {
    font-size: 1.6rem;
  }
}
.textSec {
  .checkLabel {
    display: flex;
    padding: 0;
    margin: 0;
    .form-check-input {
      width: 18px;
      height: 18px;
      margin: 0;
      vertical-align: top;
      background-color: transparent;
      border: 1.3px solid #b8bdda;
      border-radius: 5px;
    }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("./Assets/images/Fill.svg");
    }
    .form-check-input:checked {
      background-color: var(--color-primary-dark);
    }
    .form-check-label {
      font-size: 16px;
      font-family: heebo;
      color: var(--color-primary);
      margin-left: 12px;
      flex: 1;
    }
  }
}

/*------------------------*/
/*       App CheckBox     */
/*------------------------*/
/*------------------------*/
/*      Range Slider      */
/*------------------------*/
.rangeslider-horizontal {
  height: 7px;
  margin: 20px 0 12px;
  background: #cfd3e8;
  box-shadow: none;
  .rangeslider__fill {
    background-color: #082996;
  }
  .rangeslider__handle {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    background-color: #082996;
    border: 2px solid #fff;
    box-shadow: none;
    &::after {
      display: none;
    }
  }
}

/*------------------------*/
/*      Range Slider      */
/*------------------------*/
/*------------------------*/
/*        carousel        */
/*------------------------*/
.carousel {
  height: 78%;
  .carousel-indicators {
  }
  .carousel-indicators [data-bs-target] {
    width: 9px;
    height: 9px;
    background-color: var(--color-primary);
    border-radius: 100%;
  }
}

/*------------------------*/
/*        carousel        */
/*------------------------*/
/*------------------------*/
/*         Modal          */
/*------------------------*/

.modal-dialog {
  .modal-content {
    border: none;
    padding: 10px;
    // box-shadow: 0px 0px 10px #d3d3d3;
    .modal-header {
      border-bottom: 0;
      justify-content: flex-end;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

/*------------------------*/
/*         Modal          */
/*------------------------*/
/*------------------------*/
/*         Form           */
/*------------------------*/

.iForm {
  width: auto;
  line-height: 20px;
  .form-control {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 5px;
    padding: 0;
    color: #082996;
    background-color: transparent;
    padding: 3px 5px;
    border: none;
    border: 1px solid var(--color-primary);
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
  }
}
.textArea {
  .form-control {
    padding: 11px 16px;
    font-size: 16px;
    font-family: heebo;
    color: var(--color-dark);
    border: none;
    box-shadow: 1px 5px 28px rgba(156, 166, 215, 0.308056);
    border-radius: 12px;
    min-height: 47px;
  }
  .error-msg {
    font-size: 12px;
    font-family: heebo;
    color: #d04343;
    margin: 7px 0 0 9px;
  }
}
/*------------------------*/
/*         Form           */
/*------------------------*/
/*------------------------*/
/*       App Accordion    */
/*------------------------*/

.accordion-item {
  background-color: transparent;
  border: 0px solid #f1f4fb;
  border-radius: 16px !important;
  // overflow: hidden;
  .accordion-header {
    .accordion-button {
      height: 84px;
      padding: 16px;
      background-color: var(--color-white);
      box-shadow: none;
      margin-bottom: 5px;
      border-radius: 12px !important;
      box-shadow: 1px 5px 28px rgba(156, 166, 215, 0.308056);
      .iconSec {
        height: 52px;
        width: 52px;
        border-radius: 100%;
        background-color: var(--color-primary-light);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      &::after {
        background-image: url("./Assets/images/arrowDown.svg");
        background-size: unset;
        width: 12px;
      }
      .titleSec {
        margin-left: 20px;
        flex: 1;
        .heading {
          font-size: 16px;
          line-height: 23px;
          font-family: heebo700;
          color: var(--color-primary);
        }
        .labelSec {
          display: flex;
          align-items: center;
          .badge {
            height: 20px;
            width: 20px;
            border-radius: 100%;
            background-color: #4059ad !important;
            // background-color: var(--color-primary-dark);
            color: var(--color-white);
            font-weight: 400;
            font-size: 14px;
            padding: 3px 2px;
          }
          .labelSecText {
            color: #4059ad;
            font-family: heebo;
            font-size: 14px;
            margin-left: 5px;
          }
          .label {
            font-size: 14px;
            margin-left: 4px;
            color: var(--color-primary-dark);
          }
        }
      }
    }
  }
  .accordion-collapse {
    border-radius: 12px !important;
    box-shadow: 1px 5px 28px rgba(156, 166, 215, 0.308056);
    background-color: var(--color-white);
    .accordion-body {
      padding: 16px;
    }
  }
}

.addQAccordion {
  .accordion-collapse {
    box-shadow: none;
    background-color: transparent;
    .accordion-body {
      padding: 0 0 30px 0;
    }
  }
}
/*------------------------*/
/*       App Accordion    */
/*------------------------*/
