.jounrey {
  min-height: 100vh;
  // .banner {
  //     background-image: url("../../Assets/images/bannerD.svg");
  //     background-position: center;
  //     background-size: cover;
  //     background-repeat: repeat-x ;
  //     height: 30vh;
  //     width: 100%;
  //   }
  .content {
    padding: 20px;
    min-height: calc(100vh - 54px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 428px) {
      justify-content: space-evenly;
    }
    .row {
      justify-content: center;
      .grid {
        h3 {
          font-size: 29px;
          text-align: center;
          font-family: ubuntu;
        }
        p {
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          margin-top: 20px;
        }
        .btnRow {
          margin-top: 6rem;
          justify-content: center;
        }
      }
    }
    .crdRow {
      margin-top: 8px;
      justify-content: center;
    }
    .jBox {
      margin-top: 6rem;
      justify-content: center;
      .parashootBox {
        img {
          width: 109px;
          display: block;
          margin: 0 auto;
        }
        .dtl {
          font-size: 15px;
          text-align: center;
          color: var(--color-primary);
          margin-top: 23px;
        }
      }
    }
  }
}
