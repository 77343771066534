.qa {
  min-height: 100vh;
  .banner {
    background-image: url("../../Assets/images/cloudD.svg");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    height: 15vh;
    width: 100%;
  }
  .lable {
    font-size: 16px;
    color: var(--color-light);
    text-align: center;
  }
  .row {
    min-height: calc(85vh - 54px);
    justify-content: center;
    .grid {
      padding: 3.5rem;
    }
    .btnRow {
      margin-top: 5rem;
      .btn {
        height: 48px;
        width: 48px;
        border-radius: 100%;
        float: right;
      }
    }
  }
}
