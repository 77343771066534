.thankyou {
  .content {
    padding: 20px;
    .icon {
      display: block;
      width: 100px;
      margin: 0 auto;
    }
    .row {
      justify-content: center;
      margin: 0;
      .grid {
        .btnRow {
          margin-top: 6rem;
          justify-content: center;
        }
      }
    }
  }
}
