.loader {
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0.76deg, #e9ebfc -4.75%, #fcfcfe 46.53%);
}
